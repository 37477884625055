export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dramox/dashboard/Home.vue'),
  },
  {
    path: '/empresas',
    name: 'business',
    component: () => import('@/views/dramox/accounts/business/HomeBusiness.vue'),
    meta: {
      action: 'Listar empresas',
    },
  },
  {
    path: '/contactos',
    name: 'contacts',
    component: () => import('@/views/dramox/accounts/contacts/HomeContact.vue'),
    meta: {
      action: 'Listar contactos',
    },
  },
  {
    path: '/crear/empresa-contacto',
    name: 'create-business-contact',
    component: () => import('@/views/dramox/accounts/CreateBusinessAndContact.vue'),
    meta: {
      action: 'Listar contactos',
    },
  },
  {
    path: '/contactos/:id/detalle',
    name: 'contacts-detail',
    component: () => import('@/views/dramox/accounts/contacts/show/ContactView.vue'),
  },
  {
    path: '/actividades',
    name: 'activities',
    component: () => import('@/views/dramox/activities/HomeActivity.vue'),
    meta: {
      // contentRenderer: 'sidebar-left',
      // contentClass: 'todo-application',
    },
  },
  {
    path: '/actividad/editar/:id',
    name: 'activity-view',
    component: () => import('@/views/dramox/activities/view/ShowActivity.vue'),
  },
  {
    path: '/usuarios',
    name: 'users',
    component: () => import('@/views/dramox/user/HomeUser.vue'),
    meta: {
      action: 'Listar usuarios',
    },
  },
  {
    path: '/testimonios',
    name: 'testimonials',
    component: () => import('@/views/dramox/testimonial/HomeTestimonial.vue'),
  },
  {
    path: '/suscriptores',
    name: 'subscribers',
    component: () => import('@/views/dramox/subscriber/HomeSubscriber.vue'),
    meta: {
      action: 'Listar suscriptrores',
    },
  },
  {
    path: '/blogs/categorias',
    name: 'blog-category',
    component: () => import('@/views/dramox/blog/category/HomePostCategory.vue'),
    meta: {
      action: 'Listar categoria de los post',
    },
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: () => import('@/views/dramox/blog/HomePost.vue'),
    meta: {
      action: 'Ver lista post',
    },
  },
  {
    path: '/blogs/crear',
    name: 'blog-create',
    component: () => import('@/views/dramox/blog/CreatePost.vue'),
    meta: {
      action: 'Crear post',
    },
  },
  {
    path: '/blogs/:id/editar',
    name: 'blog-edit',
    component: () => import('@/views/dramox/blog/EditPost.vue'),
    meta: {
      action: 'Editar post',
    },
  },
  {
    path: '/sliders',
    name: 'sliders',
    component: () => import('@/views/dramox/elements/slider/HomeSlider.vue'),
    meta: {
      action: 'Listar slider',
    },
  },
  {
    path: '/alerta',
    name: 'alert-top',
    component: () => import('@/views/dramox/elements/alert/AlertTop.vue'),
    meta: {
      action: 'Listar y crear alerta',
    },
  },
  {
    path: '/modal-principal',
    name: 'modal-main',
    component: () => import('@/views/dramox/elements/modal/ModalMain.vue'),
    meta: {
      action: 'Listar y crear alerta',
    },
  },
  {
    path: '/guias',
    name: 'guides',
    component: () => import('@/views/dramox/guides/HomeGuide.vue'),
    meta: {
      action: 'Listar guias',
    },
  },
  {
    path: '/guias/:id/crear',
    name: 'guides-create',
    component: () => import('@/views/dramox/guides/CreateGuide.vue'),
  },
  {
    path: '/guias/:id/editar',
    name: 'guides-edit',
    component: () => import('@/views/dramox/guides/EditGuide.vue'),
  },
  {
    path: '/conductores',
    name: 'drivers',
    component: () => import('@/views/dramox/guides/driver/HomeDriver.vue'),
    meta: {
      action: 'Listar guias',
    },
  },
  {
    path: '/vehiculos',
    name: 'vehicles',
    component: () => import('@/views/dramox/guides/vehicle/HomeVehicle.vue'),
    meta: {
      action: 'Listar guias',
    },
  },
  {
    path: '/comisiones',
    name: 'commissions',
    component: () => import('@/views/dramox/commission/HomeSeller.vue'),
    meta: {
      action: 'Listar comisiones',
    },
  },
  {
    path: '/vendedor/:id/comisiones',
    name: 'seller-commissions',
    component: () => import('@/views/dramox/commission/HomeCommission.vue'),
    meta: {
      action: 'Listar comisiones',
    },
  },
  {
    path: '/ventas',
    name: 'sales',
    component: () => import('@/views/dramox/sale/HomeSale.vue'),
    meta: {
      action: 'Listar ventas',
    },
  },
  {
    path: '/ventas/:id/detalle',
    name: 'sales-show',
    component: () => import('@/views/dramox/sale/ShowSale.vue'),
    meta: {
      action: 'Reporte general ventas',
    },
  },
  {
    path: '/ventas/sin-guia',
    name: 'sales_without_guide',
    component: () => import('@/views/dramox/sale/HomeSaleWithoutGuide.vue'),
  },
  {
    path: '/reporte-general',
    name: 'report-general',
    component: () => import('@/views/dramox/sale/ReportGeneral/HomeReportGeneral.vue'),
    meta: {
      action: 'Reporte general ventas',
    },
  },
  {
    path: '/reporte-vendedor',
    name: 'report-seller',
    component: () => import('@/views/dramox/sale/ReportSeller/HomeReportSeller.vue'),
    meta: {
      action: 'Reporte vendedor ventas',
    },
  },
  {
    path: '/cotizaciones',
    name: 'quotations',
    component: () => import('@/views/dramox/quotation/HomeQuotation.vue'),
    meta: {
      action: 'Listar cotizaciones',
    },
  },
  {
    path: '/cotizaciones/crear/:currency',
    name: 'quotations-create',
    component: () => import('@/views/dramox/quotation/create/CreateQuotation.vue'),
    meta: {
      action: 'Creacion de cotizaciones',
    },
  },
  {
    path: '/cotizaciones/:id/editar',
    name: 'quotations-edit',
    component: () => import('@/views/dramox/quotation/edit/EditQuotation.vue'),
    meta: {
      action: 'Editar cotizaciones',
    },
  },
  {
    path: '/cotizaciones/:id/preview',
    name: 'quotations-show',
    component: () => import('@/views/dramox/quotation/view/ShowQuotation.vue'),
  },
  {
    path: '/cotizaciones/pendientes',
    name: 'quotations_pending',
    component: () => import('@/views/dramox/quotation/NotificationQuotationList.vue'),
  },
  {
    path: '/configuracion/general',
    name: 'setting-general',
    component: () => import('@/views/dramox/setting/SettingGeneral.vue'),
    meta: {
      action: 'Configuracion general',
    },
  },
  {
    path: '/configuracion/empresa',
    name: 'setting-business',
    component: () => import('@/views/dramox/setting/SettingBusiness.vue'),
    meta: {
      action: 'Configuracion empresa',
    },
  },
  {
    path: '/configuracion/roles',
    name: 'roles',
    component: () => import('@/views/dramox/setting/role/SettingRole.vue'),
    meta: {
      action: 'Listar roles',
    },
  },
  {
    path: '/configuracion/roles/crear',
    name: 'role-create',
    component: () => import('@/views/dramox/setting/role/SettingRoleCreate.vue'),
    meta: {
      action: 'Crear rol',
    },
  },
  {
    path: '/configuracion/roles/:id/editar',
    name: 'role-edit',
    component: () => import('@/views/dramox/setting/role/SettingRoleEdit.vue'),
    meta: {
      action: 'Editar rol',
    },
  },
  {
    path: '/productos',
    name: 'products',
    component: () => import('@/views/dramox/product/product/list/HomeProduct.vue'),
    meta: {
      action: 'Listar productos',
    },
  },
  {
    path: '/productos/:id/editar',
    name: 'product-edit',
    component: () => import('@/views/dramox/product/product/edit/EditProduct.vue'),
    meta: {
      action: 'Editar productos',
    },
  },
  {
    path: '/productos/crear',
    name: 'product-create',
    component: () => import('@/views/dramox/product/product/create/CreateProduct.vue'),
    meta: {
      action: 'Creacion de productos',
    },
  },
  {
    path: '/productos/categorias',
    name: 'product-categories',
    component: () => import('@/views/dramox/product/category/HomeCategory.vue'),
  },
  {
    path: '/productos/marcas',
    name: 'product-brands',
    component: () => import('@/views/dramox/product/brand/HomeBrand.vue'),
    meta: {
      action: 'Listar marcas',
    },
  },
  {
    path: '/productos/historial/:code?',
    name: 'product-history-stock',
    component: () => import('@/views/dramox/product/history/HomeHistoryProduct.vue'),
  },
  {
    path: '/productos/ingresos-egresos/:code',
    name: 'product-income-expenses',
    component: () => import('@/views/dramox/product/history/HomeIncomeExpenses.vue'),
  },
  {
    path: '/servicios',
    name: 'services',
    component: () => import('@/views/dramox/services/service/HomeService.vue'),
    meta: {
      action: 'Listar servicios',
    },
  },
  {
    path: '/servicios/crear',
    name: 'service-create',
    component: () => import('@/views/dramox/services/service/CreateService.vue'),
    meta: {
      action: 'Crear servicios',
    },
  },
  {
    path: '/servicios/:id/editar',
    name: 'service-edit',
    component: () => import('@/views/dramox/services/service/EditService.vue'),
    meta: {
      action: 'Editar servicio',
    },
  },
  {
    path: '/servicios/categorias',
    name: 'service-categories',
    component: () => import('@/views/dramox/services/category/HomeCategory.vue'),
  },
  {
    path: '/notificaciones',
    name: 'notifications',
    component: () => import('@/views/dramox/notification/HomeNotifications.vue'),
  },
  {
    path: '/no-autorizado',
    name: 'not-authorized',
    component: () => import('@/views/dramox/not-authorized/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
